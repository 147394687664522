import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { api, ApiError, apis, Ident } from '../../../../logic/api';
import {
	IInitProps,
	IInitState,
	InitialDataComponent,
} from '../../../../logic/handler/initialdatahandler/initialDataComponent';
import {
	IStatusState, Reporter,
} from '../../../../logic/handler/messagehandler/messageHandlerConfig';
import { INotifyAbleCustomerData, CustomerDataStore } from '../../../../logic/flux';
import { translate } from '../../../../common/language/translate';
import { FlexBox } from '../../../auth/auth.css';
import {
    KeyField,
    TitleCell,
    ValueField,
} from './basicStyledComponents/customerDetails.css';
import { IItfAccount } from '../../../../logic/types';
import { Icons } from '../../../../images';
import { CompanionConnection, EmailAddress } from '../../../../logic/api/ident';
import { DataTypeEnum, IRequestData } from '../../../../components/compositcomponents/popup/requestDataOverlay';
import { IOption } from '../../../../components/atomiccompoents/form';
import { OverlayHandler, Overlays } from '../../../../logic/handler/overlayhandler/overlayHandler';
import { MessageHandler } from '../../../../logic/handler/messagehandler/messageHandler';
import { evaluateErrorMessage } from '../../../../logic/helper/Common';

interface IProps extends IInitProps {
	personId?: number;
}
interface IState extends IStatusState, IInitState {
    person?: Ident.Person;
    accounts?: {[id: number]: Array<IItfAccount>};
    open: {[id: number] : boolean};
    companionData?: Array<Ident.Companions>,
    email: {[id: number] : Ident.EmailAddress}
}

const IconWrapper = styled.div`
    width: 26px;
    height: 24px;
    margin-right: 16px;
    svg {
        width: 16px;
        height: 16px;
    };
    :hover {
        cursor: pointer;
    }
`;
const KeyValueBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 200px;
    padding: ${(props) => props.theme.Table.TBody.Td.padding};
    margin: 8px;
    padding: 8px;
`;

const StyledViewMain = styled(FlexBox)`
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
    width: 85%;
    margin-left: 10%;
`;
const StyledContentBox = styled(FlexBox)`
	flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
    margin-bottom: 16px;
`;
const HeaderField = styled(KeyField)`
    color: ${(props) => props.theme.Global.darkFontColor};
    display: flex;
    flex-direction: row;
`;
const CompanionBlock = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 16px;
`;
export default class CustomesConnections extends InitialDataComponent<IProps, IState>
	implements INotifyAbleCustomerData {
	constructor(props: IProps) {
		super(props);

		this.state = {
            accounts: {},
            email: {},
            open: {}
		};

		this._onChangeCustomerData = this._onChangeCustomerData.bind(this);
        this.saveCompanionRelation = this.saveCompanionRelation.bind(this);
	}

	_onChangeCustomerData() {
		const usr = CustomerDataStore.getUser();
		this.setState({
            person: usr,
        }, () => { this.getCompanionData()})
    }
    
    getCompanionData() {
        if(this.state.person == null) {
            return;
        }
        const req = {
            person_id: this.state.person.person_id
        }
        api.asyncRequest<any>(
            req,
            apis.MaintenanceApi,
            'personCompanionList')
            .then((response: Array<Ident.Companions>) => {
                this.setState({
                    companionData: response,
                });
                if(response != null && response.length > 0) {
                    for (const o of response) {
                        api.asyncRequest<Array<IItfAccount>>(
                            {person_id: o.person_id},
                            apis.DefaultApi,
                            'personAccountsPersonIdGet'
                        ).then((accounts: Array<IItfAccount>) => {
                            const accs = this.state.accounts == null ? { } : this.state.accounts;
                            accs[o.person_id] = accounts;
                            this.setState({
                                accounts: accs
                            })
                        });
                        api.asyncRequest<Array<EmailAddress>>(
                            {person_id: o.person_id},
                            apis.MaintenanceApi,
                            'personEmailaddressGet'
                        ).then((mail: Array<Ident.EmailAddress>) => {
                            const mails = this.state.email;
                            if(mail.length > 0) {
                                for(const t of mail) {
                                    if(t.is_primary) {
                                        mails[o.person_id] = t;
                                    }                            }                          
                                this.setState({
                                    email: mails
                                })
                            }
                        })
                    }
                }

            }).catch( (error) => {
                this.setState({
                    companionData: []
                })
            })
     }

     openNewConnectionOverlay() {
        const temp: {[key: string]: IRequestData} = {
           other_person: {
                translationkeyLA: 'customers.details.companions.other_person.label',
                translationkeyPL: 'customers.details.companions.other_person.placeholder',
                type: DataTypeEnum.Input,
            },
            relation: {
                translationkeyLA: 'customers.details.companions.relation',
                translationkeyPL: 'customers.details.companions.relation',
                type: DataTypeEnum.Select,
                enumVals: this.getCompanionOptions(),
                value: Ident.CompanionRelationship.FirstDegreeRelative
            },
            familty_relation: {
                translationkeyLA: 'customers.details.companions.familty_relation',
                translationkeyPL: 'customers.details.companions.familty_relation',
                type: DataTypeEnum.Select,
                enumVals: [ { key: 'junior', name: 'Junior', value: 'junior'}, {key: 'family_member', name: 'Family member', value: 'family_member'}],
                value: 'junior'
            }
        };
        OverlayHandler.showOverlay(Overlays.requestDataOverlay, {data: temp, buttonKey: 'button.save', callback: this.saveCompanionRelation, height: '400px' })
     }

     saveCompanionRelation(data: {[key: string]: IRequestData}) {
        const req: Ident.PersonConnectPostRequest = {
            CompanionConnection: {
                person_id: this.state.person.person_id,
                other_person_email: data.other_person.value,
                relationship: data.relation.value as Ident.CompanionRelationship,
                family_relation: data.familty_relation.value
            }
        };
        api.asyncRequest<any>(
            req,
            apis.RegistrationApi,
            'personConnectPost')
            .then(() => {
                MessageHandler.onSuccess(Reporter['person.companion.connect.post']);
                this.getCompanionData();
            }).catch((error: ApiError) => {
                MessageHandler.onError(Reporter['person.companion.connect.post'], evaluateErrorMessage(error, true), evaluateErrorMessage(error, false));
            });
        
     }


	reviveState(newState: { [key: string]: any }): void {
	}

     getCompanionOptions() : Array<IOption> {      
        const enumValues: Array<IOption> = []; 
        for (const value in Ident.CompanionRelationship) {
            enumValues.push({
                key: 'defaultOptionKey' + value,
                name: translate('customers.details.companions.' + Ident.CompanionRelationship[value]),
                value: Ident.CompanionRelationship[value],
            });
        }
        return enumValues;
    }
    

	componentDidMount(): void {
		CustomerDataStore.addChangeListener(this._onChangeCustomerData);
		this._onChangeCustomerData();
	}

	componentWillUnmount() {
		CustomerDataStore.removeChangeListener(this._onChangeCustomerData);
	}

    generateData() {
       if(this.state.companionData == null || this.state.companionData.length === 0) {
           return <ValueField> {this.state.person != null && this.state.person.client_profile === 'companion' ? "No Primary connected" : "No Companion connected"} </ValueField>
       }
       const out = [];
       for(const data of this.state.companionData) {
            out.push
            (
            <StyledContentBox>
                <CompanionBlock >
                    <HeaderField onClick = { () => {
                        if(this.state.open[data.person_id] != null) {
                            const open = this.state.open;
                            delete open[data.person_id];
                            this.setState({
                                open: open
                            })
                        } else {
                            const open = this.state.open;
                            open[data.person_id] = true;
                            this.setState({
                                open: open
                            })
                        }
                    }}>
                        <IconWrapper>{ this.state.open[data.person_id] != null ?  Icons.arrowFacingDown()  : Icons.arrowFacingRight() }</IconWrapper>{data.given_name + " " + data.name + (data.family_relation === Ident.CompanionsFamilyRelationEnum.Junior ? "(J)" : "") }
                    </HeaderField>
                </CompanionBlock>
                {this.state.open[data.person_id] != null ? 
                <CompanionBlock >
                    <KeyValueBlock>
                            <KeyField>{translate('team.header.personId') + ": " } </KeyField>
                            <ValueField>
                            { data.person_id}
                            </ValueField>
                        </KeyValueBlock>
                        <KeyValueBlock>
                            <KeyField>{translate('customers.details.companions.type') + ": " } </KeyField>
                            <ValueField>
                            {  translate('customers.details.companions.'+ data.relation_type)}
                            </ValueField>
                        </KeyValueBlock>
                        <KeyValueBlock>
                            <KeyField> {translate('customers.details.companions.phone') + ": " }</KeyField>
                            <ValueField >
                            { data.phone_number}
                            </ValueField>
                        </KeyValueBlock>
                        <KeyValueBlock>
                            <KeyField>{ translate('customers.details.companions.relation',  'relation') + ": " } </KeyField>
                            <ValueField>
                            { translate('customers.details.companions.' + data.relationship)  }
                            </ValueField>
                        </KeyValueBlock>
                        <KeyValueBlock>
                            <KeyField>{ translate('customers.details.companions.email') + ": " } </KeyField>
                            <ValueField>
                            { this.state.email[data.person_id] != null ? this.state.email[data.person_id].email_address : '' }
                            </ValueField>
                        </KeyValueBlock>
                        <KeyValueBlock>
                            <KeyField> {translate('accountSelector.accounts')}:</KeyField>
                            <ValueField style={{display: 'flex', flexDirection: 'column'}}>
                            { this.state.accounts != null && this.state.accounts[data.person_id] != null ? this.generateAccountView(this.state.accounts[data.person_id]) : " - "}
                            </ValueField>
                        </KeyValueBlock>
                        <KeyValueBlock>
                            <KeyField> {translate('customers.details.companions.familty_relation')}:</KeyField>
                            <ValueField style={{display: 'flex', flexDirection: 'column'}}>
                            { data.family_relation}
                            </ValueField>
                        </KeyValueBlock>
                    </CompanionBlock>
                    : null }
            </StyledContentBox>)
       }
       return out;
    }

    generateAccountView(acc: Array<IItfAccount>) {
        const out = [];
        for(const o of acc) {
            out.push
            (<div style={{display:'flex', flexDirection: 'row' }}>
                    <ValueField>
                        {o.account_number}
                    </ValueField>
            </div>
            )
        }
        return out;
    }

	render() {
		return (
			<StyledContentBox>
                <TitleCell style={{margin: '32px', display: 'flex'}}>
                    {translate('customers.details.companions.linkedPersons')}
                    <IconWrapper 
                        style={{marginLeft: '16px'}}
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.openNewConnectionOverlay()
                        }}>
                        {Icons.addBeneficiary()}
                    </IconWrapper>
                </TitleCell>
                <StyledViewMain>

                    {this.generateData()}
                </StyledViewMain>
				
			</StyledContentBox>
		);
	}
}



