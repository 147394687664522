import { Account, api, apis, ApiError } from './api';
import { IItfAccount } from './types';
import { RequestMoneyResponse, PaymentsRequestMoneyBoGetRequest } from './api/account';

export class Accounts {
	static getBalance(accountNumber: string): Promise<Account.Balance> {
		return new Promise((resolve: any, reject: any) => {
			const accountRequest: Account.AccountAccountNumberBalanceGetRequest = {
				account_number: accountNumber,
			};

			api.asyncRequest<Account.Balance>(
				accountRequest,
				apis.DefaultApi,
				'accountAccountNumberBalanceGet'
			)
				.then((response) => resolve(response))
				.catch((error: ApiError) => reject(error));
		});
	}

	static getAccountsByPersonId(person_id: number): Promise<Array<IItfAccount>> {
		return new Promise((resolve: any, reject: any) => {
			const param: Account.PersonAccountsPersonIdGetRequest = {
				person_id: person_id,
			};

			api.asyncRequest<Array<Account.AccountParams>>(
				param,
				apis.DefaultApi,
				'personAccountsPersonIdGet'
			)
				.then((response) => {
					if (response == null || response.length === 0) {
						return resolve([]);
					}
					const requests = response.map((accountParam: Account.AccountParams) => {
						return Accounts.getAccountById(accountParam.account_number);
					});

					Promise.all(requests)
						.then((response: any) => {
							const accounts: Array<IItfAccount> = [];

							for (let i = 0; i < response.length; i++) {
								accounts.push(response[i]);
							}
							return resolve(accounts);
						})
						.catch((error: ApiError) => reject(error));
				})
				.catch((error: ApiError) => reject(error));
		});
	}

	static getAccountById(account_number: string): Promise<IItfAccount> {
		return new Promise((resolve: any, reject: any) => {
			const params: Account.AccountAccountNumberDetailGetRequest = {
				account_number: account_number,
			};

			const requests = [
				api.asyncRequest<Account.AccountDetail>(
					params,
					apis.DefaultApi,
					'accountAccountNumberDetailGet'
				),
				Accounts.getBalance(account_number),
			];

			Promise.all<any>(requests)
				.then((responses: Array<Account.AccountDetail | Account.Balance>) => {
					const accounts: Array<IItfAccount> = [];
					for (let i = 0; i < responses.length - 1; ) {
						const account: IItfAccount = {
							...(responses[i++] as Account.AccountDetail),
							...(responses[i++] as Account.Balance),
						};
						accounts.push(account);
					}

					return resolve(accounts.length > 0 ? accounts[0] : []);
				})
				.catch((error: ApiError) => reject(error));
		});
	}

	static getTransactions(
		account_number: string,
		date_from: Date = new Date('1/1/1970'),
		date_to: Date = new Date(),
		amount?: number,
		offset?: number,
		hideReversal?: boolean,
		txType:  Account.TxType = Account.TxType.All
	): Promise<Account.Activity> {
		return new Promise<Account.Activity>((resolve, reject) => {
			const param: Account.AccountAccountNumberActivityGetRequest = {
				account_number: account_number,
				date_from: date_from,
				date_to: date_to,
				limit: amount,
				offset: offset,
				hide_reversal: hideReversal,
				tx_type: txType
			};
			api.asyncRequest<Account.Activity>(
				param,
				apis.DefaultApi,
				'accountAccountNumberActivityGet'
			)
				.then((response) => resolve(response))
				.catch((error: ApiError) => reject(error));
		});
	}
	static getDispotransactions(
		account_number: string,
		date_from: Date = new Date('1/1/1970'),
		date_to: Date = new Date(),
		amount?: number,
		offset?: number,
		hideBlocked?: boolean
	): Promise<Account.DispoActivity> {
		return new Promise<Account.DispoActivity>((resolve, reject) => {
			const param: Account.AccountDispoActivityGetRequest = {
				account_number: account_number,
				date_from: date_from,
				date_to: date_to,
				limit: amount,
				offset: offset,
				hide_closed: hideBlocked
			};
			api.asyncRequest<Account.Activity>(
				param,
				apis.DefaultApi,
				'accountDispoActivityGet'
			)
				.then((response) => resolve(response))
				.catch((error: ApiError) => reject(error));
		});
	}

	static getPendingTransactions(person_id: number): Promise<Array<RequestMoneyResponse>> {
		return new Promise<Array<RequestMoneyResponse>>((resolve, reject) => {
			const param: PaymentsRequestMoneyBoGetRequest = {
				person_id: person_id,
			};
			api.asyncRequest<Array<RequestMoneyResponse>>(
				param,
				apis.CustomerTransactionApi,
				'paymentsRequestMoneyBoGet'
			)
				.then((response) => resolve(response))
				.catch((error: ApiError) => reject(error));
		});
	}
}
